import { gql } from "@apollo/client";

const updateInventoryWeightsMutation = gql`
	mutation updateInventoryWeightsMutation(
		$weights: [UpdateinventoryweightsWeights!]!
		$comment: String
	) {
		updateInventoryWeights(weights: $weights, comment: $comment) {
			id
			userId
			comment
			createdAt
			weights {
				type {
					type
				}
				weightInGrams
			}
		}
	}
`;

export default updateInventoryWeightsMutation;
