import React from "react";
import { Title, useNotify } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useMutation } from "@apollo/client/react";

import useInventoryWeights from "~/features/inventory/use-inventory-weights.ts";

import InventoryWeightsForm, {
	inventoryWeightsFormValuesToApi,
} from "~/features/inventory/inventory-weights-form.tsx";
import updateInventoryWeightsMutation from "~/api/update-inventory-weights-mutation.ts";

function InventoryWeights() {
	const { inventoryWeights } = useInventoryWeights();
	const notify = useNotify();

	// Setup the mutation
	const [updateInventoryWeights, { loading }] = useMutation(
		updateInventoryWeightsMutation,
		{
			onCompleted() {
				notify("Inventory weights updated", { type: "info" });
			},
			onError(error) {
				notify(error.toString(), { type: "error" });
			},
		},
	);

	const onSave = async (rawValues: any) => {
		const variables = {
			...inventoryWeightsFormValuesToApi(rawValues),
		};
		await updateInventoryWeights({ variables });
	};

	return (
		<div>
			<Title title="Manage inventory weights" />

			<Card>
				<CardContent>
					<InventoryWeightsForm
						actionName="Update Inventory Weights"
						saving={loading}
						onSave={onSave}
						inventoryWeights={inventoryWeights}
					/>
				</CardContent>
			</Card>
		</div>
	);
}

export default InventoryWeights;
