import type { ReactNode } from "react";
import React, { useState, useMemo } from "react";
import type { Validator } from "react-admin";
import { SimpleForm, NumberInput, number, TextInput } from "react-admin";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import type {
	AdminInventoryWeights,
	AdminInventoryWeightsWeight,
} from "~/api/types.ts";
import Modal from "~/components/modal.tsx";
import Skeleton from "~/components/skeleton.tsx";

type InventoryWeightsProps = {
	readonly onSave: (input: any) => Promise<void>;
	readonly saving: boolean;
	readonly children?: ReactNode;
	readonly cellValidate?: Validator | Validator[];
	readonly cellStep?: number;
	readonly actionName: string;
	readonly inventoryWeights?: AdminInventoryWeights;
};

function InventoryWeightsForm({
	onSave,
	saving,
	children,
	cellStep,
	cellValidate,
	actionName,
	inventoryWeights,
}: InventoryWeightsProps) {
	const arrangedWeights = useMemo(() => {
		return arrangeAdminInventoryWeights(inventoryWeights?.weights || []);
	}, [inventoryWeights]);

	// Create initial values from arrangedWeights
	const initialValues = useMemo(() => {
		const containers = arrangedWeights.containers.reduce(
			(acc, container) => ({
				...acc,
				[container.containerId]: container.weightInGrams,
			}),
			{},
		);

		const baseplates = arrangedWeights.baseplates.reduce(
			(acc, baseplate) => ({
				...acc,
				// prefix numeric size with "size_"
				[`size_${baseplate.size}`]: { quantity: baseplate.weightInGrams },
			}),
			{},
		);

		const other = arrangedWeights.other.reduce(
			(acc, item) => ({
				...acc,
				[item.type]: item.weightInGrams,
			}),
			{},
		);

		return { containers, baseplates, other };
	}, [arrangedWeights]);

	const allCellValidate = useMemo(() => {
		const base = [number()];
		if (!cellValidate) {
			return base;
		}
		if (Array.isArray(cellValidate)) {
			return [...base, ...cellValidate];
		}
		return [...base, cellValidate];
	}, [cellValidate]);

	const [pendingSaveValues, setPendingValues] = useState<any>();

	const onConfirmClick = async () => {
		const transformedValues = {
			baseplates: arrangedWeights.baseplates.map((baseplate) => ({
				size: baseplate.size,
				weightInGrams:
					pendingSaveValues?.baseplates?.[`size_${baseplate.size}`]?.quantity ||
					baseplate.weightInGrams,
			})),
			containers: arrangedWeights.containers.map((container) => ({
				type: container.type,
				containerId: container.containerId,
				colourId: container.colourId,
				weightInGrams:
					pendingSaveValues?.containers?.[container.containerId] ||
					container.weightInGrams,
			})),
			other: arrangedWeights.other.map((item) => ({
				type: item.type,
				weightInGrams:
					pendingSaveValues?.other?.[item.type] || item.weightInGrams,
			})),
			comment: pendingSaveValues?.comment || "",
		};
		await onSave(transformedValues);
		setPendingValues(undefined);
	};

	function WeightFormSkeleton() {
		return (
			<>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						gap: 20,
						marginTop: 20,
						marginBottom: 20,
					}}
				>
					<Skeleton height="40px" width="200px" />
				</div>
			</>
		);
	}

	return (
		<>
			<Modal
				id="pending-save"
				open={!!pendingSaveValues && !saving}
				onClose={() => setPendingValues(undefined)}
				title="Are you sure?"
				description={`This action will ${actionName}. Are you sure you want to continue?`}
				buttons={[
					<Button
						key="no"
						type="button"
						color="secondary"
						variant="outlined"
						onClick={() => setPendingValues(undefined)}
					>
						No
					</Button>,
					<Button
						key="yes"
						type="button"
						color="primary"
						variant="contained"
						onClick={onConfirmClick}
					>
						Yes
					</Button>,
				]}
			/>
			<SimpleForm
				save={setPendingValues}
				saving={saving}
				initialValues={initialValues}
			>
				<div
					style={{ display: "flex", flexDirection: "column", width: "100%" }}
				>
					{/* Containers */}
					<Typography component="h3">Containers</Typography>

					{!arrangedWeights.containers.length && <WeightFormSkeleton />}

					<div
						style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
					>
						{arrangedWeights.containers.map((container) => (
							<div key={container.containerId}>
								<Typography color="textSecondary" component="h4">
									{container.containerId}
								</Typography>
								<NumberInput
									source={`containers.${container.containerId}`}
									validate={allCellValidate}
									step={cellStep}
									label={container.containerId}
									helperText={false}
									disabled={saving}
								/>
							</div>
						))}
					</div>

					{/* Baseplates */}
					<Typography component="h3" style={{ marginTop: 20 }}>
						Baseplates
					</Typography>

					{!arrangedWeights.baseplates.length && <WeightFormSkeleton />}

					<div
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "row",
							gap: 20,
							alignItems: "center",
						}}
					>
						{arrangedWeights.baseplates.map((baseplate) => {
							return (
								<div key={baseplate.size}>
									<Typography color="textSecondary" component="h4">
										{baseplate.size}x{baseplate.size}
									</Typography>
									<NumberInput
										// now referencing "size_8", "size_16", etc.
										source={`baseplates.size_${baseplate.size}.quantity`}
										label={`Quantity (${baseplate.size}x${baseplate.size})`}
										step={cellStep}
										helperText={false}
										validate={allCellValidate}
										disabled={saving}
									/>
								</div>
							);
						})}
					</div>

					{/* Other Items */}
					<Typography component="h3" style={{ marginTop: 20 }}>
						Other Items
					</Typography>

					{!arrangedWeights.other.length && <WeightFormSkeleton />}

					<div
						style={{
							flexWrap: "wrap",
							display: "flex",
							flexDirection: "row",
							gap: 20,
						}}
					>
						{arrangedWeights.other.map((other) => (
							<NumberInput
								key={other.type}
								style={{ width: "30.33%" }}
								source={`other.${other.type}`}
								validate={allCellValidate}
								step={cellStep}
								label={other.type}
								helperText={false}
								disabled={saving}
							/>
						))}
					</div>
					<Typography component="h3" style={{ marginTop: 20 }}>
						Comment
					</Typography>
					<TextInput
						source="comment"
						style={{ width: "30.33%" }}
						disabled={saving}
					/>
				</div>
				{children}
			</SimpleForm>
		</>
	);
}

type BaseWeight = {
	weightInGrams: number;
};

type BaseplateWeight = BaseWeight & {
	size: number;
};

type ContainerWeight = BaseWeight & {
	type: string;
	containerId: string;
	colourId: string;
};

type OtherWeight = BaseWeight & {
	type: string;
};

type ArrangedWeights = {
	baseplates: BaseplateWeight[];
	containers: ContainerWeight[];
	other: OtherWeight[];
};

function arrangeAdminInventoryWeights(
	inventoryWeights: readonly AdminInventoryWeightsWeight[],
): ArrangedWeights {
	return inventoryWeights.reduce<ArrangedWeights>(
		(acc, weight) => {
			const { type, weightInGrams } = weight;

			if (type.type === "baseplate" && type.size) {
				acc.baseplates.push({
					size: type.size,
					weightInGrams,
				});
			} else if (
				type.type === "container" &&
				type.containerId &&
				type.colourId
			) {
				acc.containers.push({
					type: type.type,
					containerId: type.containerId,
					colourId: type.colourId,
					weightInGrams,
				});
			} else {
				acc.other.push({
					type: type.type,
					weightInGrams,
				});
			}

			return acc;
		},
		{ baseplates: [], containers: [], other: [] },
	);
}

type InventoryWeightsFormValues = Pick<
	AdminInventoryWeights,
	"comment" | "weights"
>;
function inventoryWeightsFormValuesToApi(
	input: ArrangedWeights & { comment?: string; userId?: string },
): InventoryWeightsFormValues {
	const weights: AdminInventoryWeightsWeight[] = [
		...input.baseplates.map((bp) => ({
			type: {
				type: "baseplate",
				size: bp.size,
				colourId: null,
				containerId: null,
			},
			weightInGrams: bp.weightInGrams,
		})),
		...input.containers.map((c) => ({
			type: {
				type: "container",
				containerId: c.containerId,
				colourId: c.colourId,
				size: null,
			},
			weightInGrams: c.weightInGrams,
		})),
		...input.other.map((o) => ({
			type: {
				type: o.type,
				containerId: null,
				colourId: null,
				size: null,
			},
			weightInGrams: o.weightInGrams,
		})),
	];

	return {
		comment: input.comment || "",
		weights,
	};
}

export type { InventoryWeightsProps };
export { inventoryWeightsFormValuesToApi };
export default InventoryWeightsForm;
